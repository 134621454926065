import debug from 'debug'
import Cart from './plugins/cart'
import History from './plugins/history'
import Polyglot from './plugins/polyglot'
import Device from './plugins/device'
import Navigation from './plugins/navigation'
import Battery from './plugins/battery'
import Wifi from './plugins/wifi'
import Settings from './plugins/settings'
import Screen from './plugins/screen'
import Network from './plugins/network'
import Media from './plugins/media'
import Content from './plugins/content'
import Http from './plugins/http.ts'
import ClientSettings from './plugins/client-settings'
// import Eva from './plugins/eva'
import XpErrorPage from './components/pages/XpErrorPage.js'
import { dateString, timeString, formatPlayTime } from './filters'

const log = debug('xpedeo-core:install')
debug.enable(process.env.VUE_APP_DEBUG)

const version = '__VERSION__'

export const $xp = {
  version
}

const coreComponents = {
  'preload-modal': () => import('./components/XpPreloadModal.vue'),
  'audio-player': () => import('./components/AudioPlayer.vue'),
  NetworkErrorScreen: () => import('./components/NetworkErrorScreen.vue'),
  NumpadInput: () => import('./components/NumpadInput.vue'),
  Panorama: () => import('./components/Panorama.vue'),
  PanzoomMap: () => import('./components/PanzoomMap.vue'),
  XpSection: () => import('./components/Section.vue'),
  Slider: () => import('./components/Slider.vue'),
  VideoPlayer: () => import('./components/VideoPlayer.vue'),
  ArEntry: () => import('./components/entries/ArEntry.vue'),
  ContactEntry: () => import('./components/entries/ContactEntry.vue'),
  EntityEntry: () => import('./components/entries/EntityEntry.vue'),
  HtmlEntry: () => import('./components/entries/HtmlEntry.vue'),
  ImageEntry: () => import('./components/entries/ImageEntry.vue'),
  MenuEntry: () => import('./components/entries/MenuEntry.vue'),
  PanoramaEntry: () => import('./components/entries/PanoramaEntry.vue'),
  TextEntry: () => import('./components/entries/TextEntry.vue'),
  VideoEntry: () => import('./components/entries/VideoEntry.vue'),
  EventEntity: () => import('./components/entries/EntityEntry/EventEntity.vue'),
  NewsEntity: () => import('./components/entries/EntityEntry/NewsEntity.vue'),
  ThreeDeeModelEntry: () => import('./components/entries/ThreeDeeModelEntry.vue'),
  XpMapPage: () => import('./components/pages/XpMapPage.vue'),
  XpMenuPage: () => import('./components/pages/XpMenuPage.vue'),
  XpMultipleChoicePage: () => import('./components/pages/XpMultipleChoicePage.vue'),
  XpClientSettingsPage: () => import('./components/pages/XpClientSettingsPage.vue'),
  XpNfcPage: () => import('./components/pages/XpNfcPage.vue'),
  XpNumericInputPage: () => import('./components/pages/XpNumericInputPage.vue'),
  XpPage: () => import('./components/pages/XpPage.vue'),
  XpSelectLanguagePage: () => import('./components/pages/XpSelectLanguagePage.vue'),
  XpSplashScreenPage: () => import('./components/pages/XpSplashScreenPage.vue'),
  Dock: () => import('./components/screens/Dock.vue'),
  Splash: () => import('./components/screens/Splash.vue'),
  PageContainer: () => import('./components/screens/PageContainer.js'),
  DynamicPage: () => import('./components/DynamicPage.js'),
  DynamicEntry: () => import('./components/DynamicEntry.js'),
  XpHomePage: () => import('./components/pages/XpHomePage.js'),
  // XpPreloadPage: () => import('./components/pages/XpPreloadPage.vue'),
  XpErrorPage: XpErrorPage,
  XpFlipbookPage: () => import('./components/pages/XpFlipbookPage.vue'),
  App: () => import('./components/App.js')
}

export default function (Vue, opts) {
  if (!opts.router) {
    throw new Error('Core plugin needs router to initialize')
  }
  if (this.__installed) { return }
  this.__installed = true

  const cfg = opts.config || {}

  // required plugins
  Cart.install(Vue, opts, $xp, cfg)
  History.install(Vue, opts, $xp, cfg)
  Polyglot.install(Vue, opts, $xp, cfg)
  Device.install(Vue, opts, $xp, cfg)
  Navigation.install(Vue, opts, $xp, cfg)
  Battery.install(Vue, opts, $xp, cfg)
  Wifi.install(Vue, opts, $xp, cfg)
  Media.install(Vue, opts, $xp, cfg)
  Settings.install(Vue, opts, $xp, cfg)
  Screen.install(Vue, opts, $xp, cfg)
  Network.install(Vue, opts, $xp, cfg)
  Http.install(Vue, opts, $xp, cfg)
  Content.install(Vue, opts, $xp, cfg)
  ClientSettings.install(Vue, opts, $xp, cfg)

  // if (cfg.enableEva) {
  //   Eva.install(Vue, opts, $xp, cfg)
  // }

  if (opts.plugins) {
    log('installing plugins')
    for (const plugin in opts.plugins) {
      switch (plugin) {
        case 'nfc':
          log('install NFC')
          import('./plugins/nfc').then(Nfc => {
            Nfc.default.install(Vue, opts, $xp, opts.plugins[plugin])
          })
          break
        case 'eva':
          import('./plugins/eva').then(Eva => {
            Eva.default.install(Vue, opts, $xp, opts.plugins[plugin])
          })
          break
        case 'positioning':
          import('./plugins/positioning.ts').then(Positioning => {
            Positioning.default.install(Vue, opts, $xp, opts.plugins[plugin])
          })
          break
        case 'tracking':
          import('./plugins/tracker.ts').then(Tracking => {
            Tracking.default.install(Vue, opts, $xp, opts.plugins[plugin])
          })
          break
        default:
          throw new Error(`Unknown xpedeo plugin "${plugin}"`)
      }
    }
  }

  Vue.prototype.$xp = $xp

  Vue.filter('dateString', dateString)
  Vue.filter('timeString', timeString)
  Vue.filter('formatPlayTime', formatPlayTime)

  const components = Object.assign({}, coreComponents, opts.components || {})
  for (const key in components) {
    Vue.component(key, components[key])
  }

  // opts.components && Object.keys(opts.components).forEach(key => {
  //   const c = opts.components[key]
  //   if (typeof c === 'function') {
  //     Vue.component(c.options.name, c)
  //   }
  // })
}
