import debug from 'debug'

const log = debug('xpedeo-core:content')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __$xp: null,
  __pageData: {},
  __liked: false,
  __glRenderer: null,
  __showPreloadModal: false,
  __preloadPath: '',
  get preloadPath () {
    return this.__preloadPath
  },
  set preloadPath (v) {
    this.__preloadPath = v
  },
  get showPreloadModal () {
    return this.__showPreloadModal
  },
  set showPreloadModal (v) {
    this.__showPreloadModal = v
  },
  get pageData () {
    return this.__pageData
  },
  get pageTitle () {
    return this.__pageData.Title
  },
  get liked () {
    return this.__liked
  },
  set liked (bLiked) {
    this.__liked = bLiked
  },
  get glRenderer () {
    return this.__glRenderer
  },
  set glRenderer (renderer) {
    this.__glRenderer = renderer
  },
  get blockedBeaconsPageIds () {
    return this.__pageData.BlockedBeaconPageIDs
  },
  get allowedBeaconsPageIds () {
    return this.__pageData.AllowedBeaconPageIDs
  },
  setPageData (value) {
    this.__pageData = {}
    this.__pageData = value
    this.__liked = this.__$xp.cart.isInCart(this.__pageData.ID)
  },
  getContentPreloaded () {
    const preloadState = window.localStorage.getItem('ContentPreloaded')
    if (preloadState === null) { return false } else {
      if (preloadState === 'true') { return true } else { return false }
    }
  },
  setContentPreloaded (value) {
    if (value) { window.localStorage.setItem('ContentPreloaded', 'true') } else { window.localStorage.setItem('ContentPreloaded', 'false') }
  },
  install (Vue, opts, $xp) {
    if ($xp.contentInstalled) { return }
    this.__$xp = $xp
    $xp.contentInstalled = true
    Vue.util.defineReactive($xp, 'content', this)
  },
  /**
   * Liefert die benötigte Breite eines Vollbild-Bildes abhängig von den verfügbaren Größen auf dem Server
   * @param binaryJson Json-Datenstruktur des Binaries
   * @returns {number}
   */
  getRequiredImageWidth (binaryJson) {
    log('getRequiredImageWidth')
    let width = (window.innerWidth || document.documentElement.clientWidth) * window.devicePixelRatio
    for (const w of this.__$xp.settings.mergedSettings.content.imageResolutions) {
      if (w >= width) {
        width = w
        break
      }
    }
    // console.log('getRequiredImageWidth() binaryJson:', binaryJson, ' ==', Math.min(binaryJson.Width, width))
    return Math.min(Number(binaryJson.Width), width)
  },
  /**
   * Liefert die URL zum Laden eines Bildes
   * @param binaryJson Json-Datenstruktur des Binaries
   * @param requiredImageWidth Gewünschte Breite; 0: Maximalgröße; null: anhand der Bildschirmbreite berechnen
   * @returns {string} URL des Bildes
   */
  getImageUrl (binaryJson, requiredImageWidth) {
    let url = this.__$xp.settings.baseUrl + 'xpedeo/' + binaryJson.Url
    const shouldGetCustomWidth = !this.__$xp.device.isLoan && !(this.__$xp.device.platform === 'ios' && window.XpedeoPreloader)
    if (shouldGetCustomWidth) {
      if (typeof requiredImageWidth === 'undefined') {
        requiredImageWidth = this.getRequiredImageWidth(binaryJson)
      }
      if (requiredImageWidth !== 0 && requiredImageWidth !== Number(binaryJson.Width)) {
        url = url.replace(/(\.[a-z]{3,4})$/, '.' + Math.round(requiredImageWidth) + '$1')
      }
    }
    return url
  }
}
