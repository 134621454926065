import localPageDataMixin from './localPageData'

const entry = {
  mixins: [localPageDataMixin],
  props: {
    entryData: {
      type: Object,
      required: true
    }
  }
}

export default entry
