import { getDeviceTimeOffset, goToAdminActivity, checkLocationSettings, getRemappedUrl, checkPermissions } from '../util/facades/device-facade'
import debug from 'debug'
import Fingerprint2 from '../util/fingerprint'
const log = debug('xpedeo-core:device')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __platform: process.env.VUE_APP_PLATFORM,
  __isLoan: process.env.VUE_APP_IS_LOAN_DEVICE,
  __deviceId: null,
  __$xp: null,

  get platform () {
    return this.__platform
  },
  get isLoan () {
    return this.__isLoan
  },
  get deviceId () {
    log('deviceId', this.__deviceId)
    return this.__deviceId
  },
  get isIPhoneOrPad () {
    const UA = window.navigator.userAgent.toLowerCase()
    return (UA && /iphone|ipad/.test(UA))
  },
  get isTouchDevice () {
    return ('ontouchstart' in document.documentElement)
  },
  get isWindows () {
    const UA = window.navigator.userAgent.toLowerCase()
    return (UA && /windows/.test(UA))
  },
  get isFirefox () {
    const UA = window.navigator.userAgent.toLowerCase()
    return (UA && /firefox/.test(UA))
  },
  get isSafari () {
    const UA = window.navigator.userAgent.toLowerCase()
    return (UA && /safari/.test(UA)) && !(UA && /chrome/.test(UA))
  },
  async getUuid () {
    if (this.__deviceId) {
      return this.__deviceId
    } else {
      this.__deviceId = window.localStorage.getItem('deviceId')
      if (this.__deviceId !== null) {
        return this.__deviceId
      } else {
        await this.generateFingerprint()
        log('generated deviceId', this.__deviceId)
        return this.__deviceId
      }
    }
  },
  async goToAdminActivity () {
    log('goToAdminActivity()')
    await goToAdminActivity()
    log('goToAdminActivity() success')
  },

  async checkLocationSettings () {
    log('checkLocationSettings()')
    try {
      await checkLocationSettings()
    } catch (error) {
      console.error(error)
      return false
    }
    log('checkLocationSettings() success')
    return true
  },

  async getRemappedUrl (url) {
    log('getRemappedUrl()')
    const remappedUrl = await getRemappedUrl(url)
    log('getRemappedUrl() success')
    return remappedUrl
  },

  async checkPermissions (permissions = []) {
    log('checkPermissions()')
    await checkPermissions(permissions)
    log('checkPermissions() success')
  },
  async generateFingerprint () {
    const getFingerprint = () => {
      Fingerprint2.get((components) => {
        const values = components.map(component => component.value)
        const murmur = Fingerprint2.x64hash128(values.join(''), 31)
        log('murmur hash(DeviceId):', murmur)
        this.__deviceId = murmur
        window.localStorage.setItem('deviceId', murmur)
      })
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(getFingerprint)
    } else {
      setTimeout(getFingerprint, 500)
    }
  },
  getDeviceTimeOffset () {
    if (process.env.VUE_APP_IS_LOAN_DEVICE) {
      getDeviceTimeOffset().then((deviceTimeOffset) => {
        return deviceTimeOffset
      })
    } else {
      log('getDeviceTimeOffset() NOT A LOAN DEVICE')
      return 0
    }
  },
  install (Vue, opts, $xp) {
    if ($xp.deviceInstalled) { return }
    this.__$xp = $xp
    $xp.deviceInstalled = true
    Vue.util.defineReactive($xp, 'device', this)
  }
}
