import debug from 'debug'

const log = debug('xpedeo-core:media')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __volume: 0.5,
  __allowUIVolumeControl: true,
  __isMediaPlaying: false,
  __showSubtitles: false,
  __isImageZoomDialogActive: false,
  get volume () {
    return this.__volume
  },
  get allowUIVolumeControl () {
    return this.__allowUIVolumeControl
  },
  get isMediaPlaying () {
    return this.__isMediaPlaying
  },
  get showSubtitles () {
    return this.__showSubtitles
  },
  get isImageZoomDialogActive () {
    return this.__isImageZoomDialogActive
  },
  setGlobalVolume (value) {
    log(`setGlobalVolume(${value})`)
    this.__volume = value
  },
  setIsMediaPlaying (value) {
    log(`setIsMediaPlaying(${value})`)
    this.__isMediaPlaying = value
  },
  setAllowUIVolumeControl (value) {
    log(`setAllowUIVolumeControl(${value})`)
    this.__allowUIVolumeControl = value
  },
  setShowSubtitles (value) {
    log(`setShowSubtitles(${value})`)
    this.__showSubtitles = value
  },
  setIsImageZoomDialogActive (value) {
    log(`setIsImageZoomDialogActive(${value})`)
    this.__isImageZoomDialogActive = value
  },
  install (Vue, opts, $xp) {
    if ($xp.mediaInstalled) { return }
    $xp.mediaInstalled = true
    Vue.util.defineReactive($xp, 'media', this)
  }
}
