import debug from 'debug'
import throttle from '../util/throttle'

const log = debug('xpedeo-core:navigation')
debug.enable(process.env.VUE_APP_DEBUG)
let showNavCounter = 0

export default {
  __isNavHidden: false,
  __isAutoHideAllowed: false,
  __isChildPagesMenuHidden: true,
  __isHomeMenuHidden: true,
  __$xp: null,
  isScreenReader: false,
  isEventConsumerEnabled: false,
  // __isAudioControlAutoHideAllowed: true,
  isFullScreen: false,
  entriesIdList: [],
  currentEntryIndex: 0,
  isAudioSynced: false,
  bManualSlideshow: false, // AudioPlayer manual slideshow
  entryNavDirection: '',
  __tabs: [],
  __activeTabId: null,
  __childPagesMenuItems: [],
  __childPagesHaveIcons: false,
  get isNavHidden () {
    return this.__isNavHidden && !this.isScreenReader
  },
  get isAutoHideAllowed () {
    return this.__isAutoHideAllowed && !this.isScreenReader
  },
  get isChildPagesMenuHidden () {
    return this.__isChildPagesMenuHidden
  },
  get isHomeMenuHidden () {
    return this.__isHomeMenuHidden
  },
  get hasPreviousEntry () {
    return this.currentEntryIndex > 0
  },
  get hasNextEntry () {
    return this.currentEntryIndex < this.entriesIdList.length - 1
  },
  // get isAudioControlAutoHideAllowed () {
  //   return this.__isAudioControlAutoHideAllowed
  // },
  get currentEntry () {
    return this.entriesIdList[this.currentEntryIndex]
  },
  get isEntryNavVisible () {
    return !this.isAudioSynced && this.entriesIdList.length > 1 && this.isFullScreen
  },
  // set autoHideAudioControl (value) {
  //   this.__isAudioControlAutoHideAllowed = value
  // },
  get tabs () {
    return this.__tabs
  },
  get activeTabId () {
    return this.__activeTabId
  },
  get childPagesMenuItems () {
    return this.__childPagesMenuItems
  },
  get childPagesHaveIcons () {
    return this.__childPagesHaveIcons
  },
  toggleChildPagesMenu () {
    this.__isChildPagesMenuHidden = !this.__isChildPagesMenuHidden
  },
  toggleHomeMenu () {
    this.__isHomeMenuHidden = !this.__isHomeMenuHidden
  },
  allowAutoHide () {
    log('allow auto hide')
    this.__isAutoHideAllowed = true
  },
  preventAutoHide () {
    log('prevent auto hide')
    this.__isAutoHideAllowed = false
  },
  // allowAudioControlAutoHide () {
  //   this.__isAudioControlAutoHideAllowed = true
  // },
  preventAudioControlAutoHide () {
    this.__isAudioControlAutoHideAllowed = false
  },
  initEntriesIdList (list) {
    this.entriesIdList = list
    this.entryNavDirection = null
    this.currentEntryIndex = 0
  },
  goToNextEntry () {
    this.entryNavDirection = 'next'
    this.currentEntryIndex = Math.abs((this.currentEntryIndex + 1) % this.entriesIdList.length)
    console.log('currentEntryIndex', this.currentEntryIndex)
  },
  goToPreviousEntry () {
    this.entryNavDirection = 'previous'
    let index = (this.currentEntryIndex - 1) % this.entriesIdList.length
    if (index < 0) {
      index += this.entriesIdList.length
    }
    this.currentEntryIndex = index
    console.log('currentEntryIndex', this.currentEntryIndex)
  },
  goToEntry (entryId) {
    console.log('goto', entryId)
    this.currentEntryIndex = this.entriesIdList.indexOf(entryId)
    console.log('currentEntryIndex', this.currentEntryIndex)
  },
  /**
   * show Navigation
   * @param duration: (undefined: do not autohide, number: autohide after milliseconds, true: autohide after default autoHideNavDuration)
   */
  showNav (duration) {
    duration = (duration === true) ? this.__$xp.settings.mergedSettings.navigation.autoHideNavDuration : duration
    // Ausnahme SplashScreen:
    if (this.__$xp.content && this.__$xp.content.pageData && this.__$xp.content.pageData.Class === 'XpSplashScreen') {
      this.__isNavHidden = true
      this.preventAutoHide()
      return
    }
    clearTimeout(showNavCounter)
    this.__isNavHidden = false
    if (duration && this.isAutoHideAllowed) {
      showNavCounter = setTimeout(() => {
        this.__isNavHidden = true
      }, duration)
    }
  },
  hideNav () {
    clearTimeout(showNavCounter)
    this.__isNavHidden = true
  },
  setTabs (tabs) {
    this.__tabs = tabs
  },
  resetTabs () {
    this.__tabs = []
    this.__activeTabId = null
  },
  setActiveTabId (tabId) {
    if (this.__activeTabId !== parseInt(tabId)) {
      this.__activeTabId = parseInt(tabId)
    }
  },
  setChildPagesMenuItems (items) {
    for (const item of items) {
      if (item.icon && (item.icon.Type !== 'icon' || item.icon.Name !== 0)) {
        this.__childPagesHaveIcons = true
        break
      }
    }
    this.__childPagesMenuItems = items
  },
  resetChildPagesMenuItems () {
    this.__childPagesHaveIcons = false
    this.__childPagesMenuItems = []
  },
  initializeTouchEvents () {
    const onTouchStart = (e) => {
      // console.log('onTouchStart()', e)
      if (this.__$xp.navigation.isAutoHideAllowed) {
        this.__$xp.navigation.showNav()
      }
      this.__$xp.screen.postponeDim()
      /*
        iPhones starting with iOS10 ignore "user-scalable=no" "to improve accessibility"
        To prevent pinch zooming the whole site, we ignore multi-touch-events if the event is not handled before.
       */
      if (this.__$xp.device.isIPhoneOrPad) {
        if (!e.handled && e.touches && e.touches.length > 1) {
          e.preventDefault()
        }
      }
    }
    const onTouchEnd = (e) => {
      // console.log('onTouchEnd()', e)
      if (this.__$xp.device.platform === 'browser' && !(this.__$xp.device.isIPhoneOrPad && !this.__$xp.device.isSafari)) {
        this.__$xp.screen.requestFullscreen()
      }
      if (this.__$xp.navigation.isAutoHideAllowed) {
        this.__$xp.navigation.showNav(true)
      }
      /*
        iPhones starting with iOS10 ignore "user-scalable=no" "to improve accessibility"
        To prevent zooming the whole site on double click, we ignore double clicks.
       */
      if (this.__$xp.device.isIPhoneOrPad) {
        const now = (new Date()).getTime()
        if (now - this.lastTouchEnd <= 300) {
          e.preventDefault()
        }
        this.lastTouchEnd = now
      }
    }
    const onTouchEndHandler = throttle(onTouchEnd, 100)
    const onTouchStartHandler = throttle(onTouchStart, 100)
    window.addEventListener('touchstart', onTouchStartHandler, false)
    window.addEventListener('mousedown', onTouchStartHandler, false)
    window.addEventListener('touchend', onTouchEndHandler, false)
    window.addEventListener('mouseup', onTouchEndHandler, false)
  },
  getUniqueSelector (node) {
    let selector = ''
    while (node.parentElement) {
      if (node.id) {
        return '#' + node.id + `${selector ? ' > ' : ''}${selector}`
      }

      const nodeName = node.tagName.toLowerCase()
      const siblings = Array.from(node.parentElement.children).filter(
        e => e.tagName === node.tagName
      )

      selector = nodeName + (siblings.indexOf(node)
        ? `:nth-of-type(${siblings.indexOf(node) + 1})`
        : '') + `${selector ? ' > ' : ''}${selector}`
      node = node.parentElement
    }
    return selector
  },
  install (Vue, opts, $xp) {
    if ($xp.navigationInstalled) { return }
    $xp.navigationInstalled = true
    this.__$xp = $xp
    this.initializeTouchEvents()
    Vue.util.defineReactive($xp, 'navigation', this)
  }
}
