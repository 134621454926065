import Vue from 'vue'

const EventHub = {
  Bus: new Vue(),
  TYPES: {
    RE_INIT_IMAGE_MAP: 're-initialize-image-map',
    START_PLAY_MEDIA: 'start-play-media',
    ANDROID_ACTIVITY_PAUSE: 'android-activity-pause',
    CLICKED_HOTSPOT_SPLASHSCREEN: 'clicked_hotspot_splashscreen'
  }
}

export default EventHub
