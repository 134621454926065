import debug from 'debug'

const log = debug('xpedeo-core:history')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __homePages: [],
  __nextPageId: null,
  __historyArray: [],
  __router: null,
  __lastPanoramaPageId: null,
  __lastPanoramaHomePageId: null,
  __lastHomePage: null,
  __$xp: null,
  // @deprecated
  get homePageId () {
    if (this.__homePages.length) {
      return this.__homePages[this.__homePages.length - 1].id
    }
    return null
  },
  // @deprecated
  get previousHomePageId () {
    if (this.__homePages.length > 1) {
      return this.__homePages[this.__homePages.length - 2].id
    }
    return null
  },
  // @deprecated
  get homePage () {
    if (this.__homePages.length) {
      return this.__homePages[this.__homePages.length - 1]
    }
    return null
  },
  // @deprecated
  get previousHomePage () {
    if (this.__homePages.length > 1) {
      return this.__homePages[this.__homePages.length - 2]
    }
    return null
  },
  get lastHomePage () {
    return this.__lastHomePage || undefined
  },
  get nextPageId () {
    return this.__nextPageId || null
  },
  get historyArray () {
    return this.__historyArray
  },
  get historyParams () {
    return this.__historyArray.length > 0 ? this.__historyArray[this.__historyArray.length - 1].params : {}
  },

  set nextPageId (id) {
    this.__nextPageId = id
  },

  get homepagesList () {
    const hlist = this.__historyArray.filter(item => item.title !== undefined && (this.__$xp.content.pageData.Homepage == null || item.pageId !== this.__$xp.content.pageData.Homepage.ID))
    // log('filtered hlist ', hlist)
    const cleanHlist = []
    cleanHlist.push(hlist[hlist.length - 1])
    const newHomepageIdsItem = hlist[hlist.length - 1]
    if (hlist.length > 1 && hlist[hlist.length - 1].ancestorIds && hlist[hlist.length - 1].ancestorIds.length >= 1) {
      for (let index = hlist.length - 2; index >= 0; index--) {
        const newAncestors = hlist[index + 1]
        const OldElement = hlist[index]
        // log('comparing --', newHomepageIdsItem, '--', OldElement, '--', newAncestors)
        if (!this.checkHomepagesConflict(newHomepageIdsItem.ancestorIds, OldElement.ancestorIds) && !this.checkHomepagesConflict(newAncestors.ancestorIds, OldElement.ancestorIds)) {
          cleanHlist.push(OldElement)
        }
      }
      cleanHlist.reverse()
      log('get homepagesList', cleanHlist)
      return cleanHlist
    } else {
      log('get homepagesList', hlist)
      return hlist
    }
  },

  setLastPanoramaPage (panoramaPageId, homePageId) {
    this.__lastPanoramaPageId = panoramaPageId
    this.__lastPanoramaHomePageId = homePageId
  },

  getLastPanoramaPage (homePageId) {
    return this.__lastPanoramaHomePageId === homePageId ? this.__lastPanoramaPageId : null
  },

  resetHistoryState () {
    log('reset history state')
    this.__homePages = []
    this.__nextPageId = null
    this.__historyArray = []
    this.__lastHomePage = null
  },
  checkHomepagesConflict (hps1, hps2) {
    if (hps1 === undefined || hps2 === undefined || hps1.length === 0 || hps2.length === 0) {
      return false
    }
    if (hps1[hps1.length - 1] === hps2[hps2.length - 1] || hps1[hps1.length - 1] === hps2[0] || hps1.length < hps2.length) {
      return true
    } else {
      log('returning false', hps1, hps2)
      return false
    }
  },
  addHomePage (pageId, pagePath, pageTitle, parentId, ancestorIds) {
    pageId = parseInt(pageId)
    log(`addHomePage(${pageId}, ${pagePath}, ${pageTitle}, ${parentId}, ${ancestorIds})`)
    log('adding homepage, history array', this.__historyArray)
    const searchIndex = this.__historyArray.findIndex(item => item.pageId === parseInt(pageId))
    log(`searchIndex for homepage ${searchIndex}`)
    if (searchIndex > -1) { // Seite ist bereits in der History
      if (this.__historyArray[searchIndex].parentId === undefined) {
        // page exists in history as normal page, mark as homepage
        this.__historyArray[searchIndex].parentId = parentId
        this.__historyArray[searchIndex].title = pageTitle
        this.__historyArray[searchIndex].ancestorIds = ancestorIds
      }
      this.__lastHomePage = this.__historyArray[searchIndex]
      // }
    } else { // Seite hinzufügen
      // page does not exist in history, add as homepage
      log('should add homepage', pageId)
      this.__historyArray.push({
        pageId: pageId,
        path: pagePath,
        title: pageTitle,
        parentId: parentId,
        ancestorIds: ancestorIds
      })
      const tempArray = this.__historyArray.map(a => Object.assign({}, a))

      tempArray.reverse()
      let lastToAssign = null

      tempArray.every(element => {
        if (this.__$xp.content.pageData.ID !== element.pageId && element.title !== null &&
          ((this.__$xp.content.pageData.Homepage === undefined || this.__$xp.content.pageData.Homepage === null) || (this.__$xp.content.pageData.Homepage.ID && this.__$xp.content.pageData.Homepage.ID !== element.pageId))) {
          lastToAssign = element
          return false
        } else {
          return true
        }
      })
      if (lastToAssign) {
        this.__lastHomePage = lastToAssign
      }
    }
    log('added homepage, history array', this.__historyArray)
  },
  push (newPageId, newPath) {
    const savedHistory = JSON.parse(window.localStorage.getItem('history'))
    const searchIndex = this.__historyArray.findIndex(item => item.pageId === parseInt(newPageId))

    if (this.__$xp && !this.__$xp.device.isLoan && savedHistory && this.__historyArray.length === 0 && savedHistory.length > 1 && savedHistory[savedHistory.length - 1].pageId === newPageId) {
      // History ist leer und in Storage ist eine History der aktuellen Seite (Seite neu geladen)
      log('History aus localStorage genommen')
      this.__historyArray = savedHistory
    } else if (searchIndex > 0) { // Seite ist bereits in der History
      if (searchIndex < this.__historyArray.length) {
        this.__historyArray = this.__historyArray.slice(0, searchIndex + 1)
      }
    } else { // Seite hinzufügen
      this.__historyArray.push({
        pageId: parseInt(newPageId),
        path: newPath,
        params: {}
      })
    }
    log('pushed to history array', this.__historyArray)
    window.localStorage.setItem('history', JSON.stringify(this.__historyArray))
    // window.localStorage.setItem('homepages', JSON.stringify(this.__homePages))
  },
  replace (newPageId, newPath) {
    this.__historyArray.splice(-1, 1)
    this.push(newPageId, newPath)
    log('replaced in history array', this.__historyArray)
  },
  setHistoryParam (pageId, key, value) {
    pageId = parseInt(pageId)
    if (this.__historyArray.length > 0) {
      if (this.__historyArray[this.__historyArray.length - 1].pageId === pageId) {
        this.__historyArray[this.__historyArray.length - 1].params = Object.assign(
          {},
          this.__historyArray[this.__historyArray.length - 1].params,
          { [key]: value })
      } else {
        console.warn(`setHistoryParam: ${key} = ${value}. pageId does not match the last element in the history array \n ${pageId} was passed and ${this.__historyArray[this.__historyArray.length - 1].pageId} was found`)
      }
    }
  },
  back (replace) {
    if (this.__historyArray.length > 1) {
      // const tempArray = this.__historyArray.map(a => Object.assign({}, a))

      // tempArray.reverse()
      // get last non-homepage entry
      let lastPageIndex = 0
      for (let index = this.__historyArray.length - 2; index >= 0; index--) {
        if (this.__historyArray[index].parentId === undefined) {
          lastPageIndex = index
          break
        }
      }
      const lastPagePath = this.__historyArray[lastPageIndex].path
      // commit('BACK')
      this.__historyArray.splice(-1, this.__historyArray.length - 1 - lastPageIndex)
      this.__router.push({ path: lastPagePath, query: { back: true }, replace })
    }
  },
  goHome (replace) {
    if (this.lastHomePage) {
      log('GO HOME: (previous)' + this.lastHomePage.path)
      this.__router.push({ path: this.lastHomePage.path, replace })
    } else {
      log('GO START')
      this.__router.push({ path: '/', replace })
    }
  },
  saveHistoryState () {
    log('Saving history state')
    // window.localStorage.setItem('HomePages', JSON.stringify(this.__homePages))
    window.localStorage.setItem('nextPageId', this.__nextPageId)
    window.localStorage.setItem('historyArray', JSON.stringify(this.__historyArray))
  },
  restoreHistoryState () {
    log('Restoring history state')

    const storedNextPageId = window.localStorage.getItem('nextPageId')
    this.__nextPageId = storedNextPageId ? Number(storedNextPageId) : null
    window.localStorage.setItem('nextPageId', '')

    this.__historyArray = JSON.parse(window.localStorage.getItem('historyArray'))
    window.localStorage.setItem('historyArray', '')
  },
  install (Vue, opts, $xp) {
    if ($xp.historyInstalled) { return }
    this.__$xp = $xp
    if (!opts.router) {
      throw new Error('History plugin needs router to initialize')
    }
    this.__router = opts.router
    $xp.historyInstalled = true
    Vue.util.defineReactive($xp, 'history', this)
  }
}
