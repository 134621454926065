import debug from 'debug'

const log = debug('xpedeo-core:network')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  __isOnline: true,
  __onlineObservers: [],
  __offlineObservers: [],
  get isOnline () {
    return this.__isOnline
  },
  subscribe (eventType, cb) {
    log(`subscribe(${eventType}, ${cb})`)
    if (eventType === 'online') {
      this.__onlineObservers.push(cb)
    } else if (eventType === 'offline') {
      this.__offlineObservers.push(cb)
    } else {
      throw new Error(`xpedeo-core:network unknown event type ${eventType}`)
    }
  },
  unsubscribe (eventType, cb) {
    log(`unsubscribe(${eventType}, ${cb})`)
    if (eventType === 'online') {
      this.__onlineObservers = this.__onlineObservers.filter(subscriber => subscriber !== cb)
    } else if (eventType === 'offline') {
      this.__offlineObservers = this.__offlineObservers.filter(subscriber => subscriber !== cb)
    } else {
      throw new Error(`xpedeo-core:network unknown event type ${eventType}`)
    }
  },
  notify (eventType) {
    log(`notify(${eventType})`)
    if (eventType === 'online') {
      this.__onlineObservers.forEach(observer => observer())
    } else if (eventType === 'offline') {
      this.__offlineObservers.forEach(observer => observer())
    } else {
      throw new Error(`xpedeo-core:network unknown event type ${eventType}`)
    }
  },
  startListening () {
    log('startListening()')
    this.__isOnline = navigator.onLine
    window.addEventListener('offline', this.onOffline.bind(this), false)
    window.addEventListener('online', this.onOnline.bind(this), false)
  },
  stopListening () {
    log('stopListening()')
    window.removeEventListener('offline', this.onOffline, false)
    window.removeEventListener('online', this.onOnline, false)
  },
  onOffline () {
    log('onOffline()')
    this.__isOnline = false
    this.notify('offline')
  },
  onOnline () {
    log('onOnline()', this)
    this.__isOnline = true
    this.notify('online')
  },
  waitForOnline (timeoutMilliseconds = 10000) {
    log(`waitForOnline(${timeoutMilliseconds})`)
    return new Promise((resolve, reject) => {
      if (this.__isOnline) {
        resolve()
      } else {
        let interval = null
        const timeout = setTimeout(() => {
          clearInterval(interval)
          reject(new Error('wait for online timeout.'))
        }, timeoutMilliseconds)
        interval = setInterval(() => {
          if (this.__isOnline) {
            clearTimeout(timeout)
            resolve()
          }
        }, 500)
      }
    })
  },
  recheckIsOnline () {
    log('recheckIsOnline()')
    if (navigator.onLine) {
      this.onOnline()
    } else {
      this.onOffline()
    }
    return this.__isOnline
  },
  install (Vue, opts, $xp) {
    if ($xp.networkInstalled) { return }
    $xp.networkInstalled = true
    Vue.util.defineReactive($xp, 'network', this)
  }
}
