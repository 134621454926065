import screenFacade from '../util/facades/screen-facade'
import debug from 'debug'
import { listenOpts } from '../util/event'
import debounce from '../util/debounce'

const { passive } = listenOpts
const log = debug('xpedeo-core:screen')
debug.enable(process.env.VUE_APP_DEBUG)

const prefixes = {}

// needed for consistency across browsers,
// including IE11 which does not return anything
function promisify (target, fn) {
  try {
    const res = target[fn]()
    return res === undefined
      ? Promise.resolve()
      : res
  } catch (err) {
    return Promise.reject(err)
  }
}

let dimTimeout = 0
/**
 * This plugin require cordova-plugin-screen-orientation
 */
export default {
  __brightness: null,
  __isDimPrevented: false,
  __isHalfDim: false,
  __isFullDim: false,
  __isIdle: false,
  __isFullscreenActive: false,
  __canFullscreen: false,
  __orientation: null,
  __isBrowserNavBarVisible: false,
  __$xp: null,
  __width: 0,
  __height: 0,
  get isFullscreenActive () {
    return this.__isFullscreenActive
  },
  get orientation () {
    return this.__orientation
  },
  get isBrowserNavBarVisible () {
    return this.__isBrowserNavBarVisible
  },
  get defaultBrightness () {
    return this.__$xp.settings.getValue('screen.brightness')
  },
  get halfDimMilliseconds () {
    return this.__$xp.settings.getValue('screen.halfDimMilliseconds')
  },
  get fullDimMilliseconds () {
    return this.__$xp.settings.getValue('screen.fullDimMilliseconds')
  },
  get halfDimValue () {
    return this.__$xp.settings.getValue('screen.halfDimValue')
  },
  get fullDimValue () {
    return this.__$xp.settings.getValue('screen.fullDimValue')
  },
  get isIdle () {
    return this.__isIdle
  },
  get width () {
    return this.__width
  },
  get height () {
    return this.__height
  },
  async setBrightness (brightness) {
    log(`setBrightness(${brightness})`)
    try {
      this.__brightness = await screenFacade.setBrightness((Number.isInteger(brightness)) ? brightness : this.defaultBrightness)
      log('setBrightness success')
    } catch (e) {
      log('setBrightness error', e)
    }
  },
  preventDim () {
    log('preventDim()')
    clearTimeout(dimTimeout)
    this.__isDimPrevented = true
  },
  async allowDim () {
    log('allowDim()')
    this.__isDimPrevented = false
    await this.postponeDim()
  },
  async postponeDim () {
    log('postponeDim()')
    clearTimeout(dimTimeout)
    await this.setBrightness()
    this.__isHalfDim = false
    this.__isFullDim = false
    this.__isIdle = false
    if (!this.__isDimPrevented) {
      dimTimeout = setTimeout(() => {
        this.setBrightness(this.halfDimValue)
        this.__isHalfDim = true
        this.__isIdle = true
        clearTimeout(dimTimeout)
        dimTimeout = setTimeout(() => {
          this.setBrightness(this.fullDimValue)
          this.__isHalfDim = false
          this.__isFullDim = true
        }, this.fullDimMilliseconds - this.halfDimMilliseconds)
      }, this.halfDimMilliseconds)
    }
  },
  cancelDimTimer () {
    log('cancelDimTimer()')
    clearTimeout(dimTimeout)
  },
  preventSleep () {
    log('preventSleep()')
    screenFacade.preventSleep()
  },
  allowSleep () {
    log('allowSleep()')
    screenFacade.allowSleep()
  },
  setOrientation (orientation) {
    log('setOrientation()')
    screenFacade.setOrientation(orientation)
    this.__$xp.navigation.showNav(true)
  },
  requestFullscreen (target, evenOnNonMobile) {
    return !this.__$xp.navigation.isScreenReader && (evenOnNonMobile || this.__$xp.device.isTouchDevice) && (this.__canFullscreen && !this.__isFullscreenActive)
      ? promisify(target || document.documentElement, prefixes.request)
      : this.__getErr()
  },
  exitFullscreen () {
    return this.__canFullscreen && this.__isFullscreenActive
      ? promisify(document, prefixes.exit)
      : this.__getErr()
  },
  toggleFullscreen (target, evenOnNonMobile) {
    return this.isFullscreenActive
      ? this.exitFullscreen()
      : this.requestFullscreen(target, evenOnNonMobile)
  },
  initFullscreen () {
    this.__getErr = () => Promise.resolve()

    prefixes.request = [
      'requestFullscreen',
      'msRequestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen'
    ].find(request => document.documentElement[request])

    this.__canFullscreen = prefixes.request !== undefined

    if (this.__canFullscreen === false) {
      // it means the browser does NOT support it
      this.__getErr = () => Promise.reject(new Error('Not capable to request fullscreen'))
      return
    }

    this.__getErr = () => Promise.resolve()

    prefixes.exit = [
      'exitFullscreen',
      'msExitFullscreen', 'mozCancelFullScreen', 'webkitExitFullscreen'
    ].find(exit => document[exit])

    this.__isFullscreenActive = !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );

    [
      'onfullscreenchange',
      'onmsfullscreenchange',
      'onwebkitfullscreenchange'
    ].forEach(event => {
      document[event] = () => {
        this.__isFullscreenActive = !this.__isFullscreenActive
      }
    })
  },
  initOrientation () {
    if (this.__$xp.device.platform === 'ios' || (this.__$xp.device.platform === 'browser' && this.__$xp.device.isIPhoneOrPad)) {
      this.__orientation = this.getIosOrientation()
    } else if (window.screen.orientation) {
      this.__orientation = window.screen.orientation ? window.screen.orientation.type : 'undefined'
    }
    window.addEventListener('orientationchange', () => {
      if (this.__$xp.device.platform === 'ios' || (this.__$xp.device.platform === 'browser' && this.__$xp.device.isIPhoneOrPad)) {
        this.__orientation = this.getIosOrientation()
        const event = new Event('resize')
        const target = window.visualViewport !== undefined ? window.visualViewport : window
        target.dispatchEvent(event)
      } else {
        this.__orientation = screen.orientation ? screen.orientation.type : 'undefined'
      }
      this.__$xp.navigation.showNav(true)
    }, true)
  },
  getIosOrientation () {
    if (window.orientation !== undefined) {
      switch (window.orientation) {
        case 0 :
          return 'portrait-primary'
        case 180:
          return 'portrait-secondary'
        case -90:
          return 'landscape-primary'
        case 90:
          return 'landscape-secondary'
        default:
          break
      }
    } else return 'undefined'
  },
  initResizeHandler () {
    const target = window.visualViewport !== undefined ? window.visualViewport : window
    const update = () => {
      log('window resize update handler')
      if (window.innerHeight !== this.__height) {
        this.__height = window.innerHeight
      }
      if (window.innerWidth !== this.__width) {
        this.__width = window.innerWidth
      }
      const outerHeight = window.outerHeight
      const windowHeight = window.innerHeight
      log('outerHeight', outerHeight, windowHeight, this.__orientation)
      if (this.__orientation && this.__orientation.includes('portrait')) {
        this.__isBrowserNavBarVisible = false
        return
      }
      this.__isBrowserNavBarVisible = (windowHeight < outerHeight)
      if (this.__isBrowserNavBarVisible) {
        this.__$xp.navigation.showNav()
      }
      log('BrowserNavBar is ' + (this.__isBrowserNavBarVisible ? 'visible' : 'NOT visible'))
    }
    const updateHandler = debounce(update, 500)
    target.addEventListener('resize', updateHandler, passive)
  },
  install (Vue, opts, $xp) {
    if ($xp.screenInstalled) { return }
    $xp.screenInstalled = true
    this.__$xp = $xp
    this.initFullscreen()
    this.initOrientation()
    this.initResizeHandler()
    Vue.util.defineReactive($xp, 'screen', this)
  }
}
