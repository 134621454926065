export default {
  __$xp: null,
  __locale: 'de_DE',
  __urlPath: '_/',
  __defaultLocale: null,
  __i18n: null,
  get locale () {
    return this.__locale
  },
  get urlPath () {
    return this.__urlPath
  },
  get rtl () {
    return (this.__locale.startsWith('ar_') || this.__locale.startsWith('fa_') || this.__locale.startsWith('he_'))
  },
  get apiLangPath () {
    return this.__urlPath === '_/' ? '' : this.__urlPath
  },
  set locale (locale) {
    this.__locale = locale
  },
  set urlPath (urlPath) {
    this.__urlPath = urlPath || '_/'
  },
  setLanguage (locale, urlPath) {
    this.__locale = locale
    this.__urlPath = urlPath || '_/'
    this.setI18nLanguage(locale)
    this.__$xp.settings.reloadSettings(urlPath)
  },
  setI18nLanguage (lang) {
    lang = lang || this.__defaultLocale
    this.__i18n.locale = lang
    const langParts = lang.split('_')
    if (langParts.length !== 2) {
      throw new Error(`setI18nLanguage: invalid locale ${lang}`)
    }
    document.querySelector('html').setAttribute('lang', langParts[1].toLowerCase())
  },
  install (Vue, opts, $xp, cfg) {
    if ($xp.polyglotInstalled) { return }
    this.__defaultLocale = cfg.defaultLocale
    this.__i18n = cfg.i18n
    $xp.polyglotInstalled = true
    this.__$xp = $xp
    this.setI18nLanguage()
    Vue.util.defineReactive($xp, 'polyglot', this)
    // $xp.polyglot = this
  }
}
