export const dateString = (isoDateString) => {
  const date = new Date(isoDateString)
  return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`
}

export const timeString = (isoDateString) => {
  const date = new Date(isoDateString)
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}

export const formatPlayTime = (time) => {
  if (time > 0) {
    const minutes = Math.floor(time / 60)
    const seconds = (time % 60).toFixed(0).padStart(2, '0')
    return minutes + ':' + seconds
  }
  if (time < 0) {
    time *= -1
    const minutes = Math.floor(time / 60)
    const seconds = (time % 60).toFixed(0).padStart(2, '0')
    return '-' + minutes + ':' + seconds
  }
  return '0:00'
}
